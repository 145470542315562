import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { ShopService } from '../../../services/shop.service';
import { FormsModule, NgModel } from '@angular/forms';
import { CookiesComponent } from "../cookies/cookies.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, CookiesComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  mail: string = '';
  constructor(private shopSrv: ShopService) {}
  signUp() {
    console.log(this.mail);
    this.shopSrv.signup(this.mail).subscribe((res: any) => {});
  }
  
}
