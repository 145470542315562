<section>
   <!-- Learn More Overlay -->
    <div id="cookie-banner" class="cookie-banner" [ngClass]="{'cookie-banner--hide': acceptCookies || hideBanner}">
        <span>
            Wir nutzen Cookies, um personalisierte Inhalte darzustellen, Social Media einzubinden und Nutzerverhalten zu
            analysieren. Das Ablehnen von Cookies kann das Nutzungsverhalten negativ beeinflussen.
        </span>
        <span id="cookie-banner-button" class="cookie-banner-button" (click)="cookies(1)">Cookies akzeptieren</span>
        <span class="cookie-banner-button" (click)="toggleLearnMore()">Cookie Einstellungen</span>
    </div>

    <!-- Learn More Textbox -->
    <div id="learn-more-textbox" class="learn-more-textbox cookie-banner" [ngClass]="{'learn-more-textbox--show': showLearnMore}">
        <div class="learn-more-content">
            <h2 class="learn-more-content-text">Mehr zu unserer Cookie-Nutzung</h2>
            <p class="learn-more-content-text">Wir verwenden Cookies, um Ihre Erfahrung zu verbessern und
                personalisierte Inhalte bereitzustellen. Durch
                die Verwendung unserer Seite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie
                in unserer <a [routerLink]="['/policies/privacy-policy']">Datenschutzerklärung.</a></p>

            <!-- Slider for Cookies -->
            <!-- Slider for Cookies -->
            <div class="cookie-slider">
                <label for="required-cookies">Erforderliche Cookies</label>
                <div class="switch" [ngClass]="{'active': requiredCookies === 1}"
                    (click)="toggleCookie('requiredCookies')"></div>
            </div>

            <div class="cookie-slider">
                <label for="personalized-cookies">Personalisierte Cookies</label>
                <div class="switch" [ngClass]="{'active': personalizedCookies === 1}"
                    (click)="toggleCookie('personalizedCookies')"></div>
            </div>

            <div class="cookie-slider">
                <label for="marketing-cookies">Marketing- und Analysecookies</label>
                <div class="switch" [ngClass]="{'active': marketingCookies === 1}"
                    (click)="toggleCookie('marketingCookies')"></div>
            </div>
            <span id="cookie-banner-button" class="cookie-banner-button" (click)="cookies(0)">Einstellungen speichern</span>

            <span class="cookie-banner-button" (click)="toggleLearnMore()">Schließen</span>
        </div>
    </div>
</section>   