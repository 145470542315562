import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-cookies',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss'
})
export class CookiesComponent implements OnInit{
  constructor(private storageService:StorageService){ 

  }
// Zustände der Cookies, initial auf 0 (Nicht Akzeptiert)
requiredCookies = 1; // Erforderliche Cookies müssen immer akzeptiert werden
personalizedCookies = 1;
marketingCookies = 0;

acceptCookies = false;
showLearnMore = false;
hideBanner =false;
ngOnInit(): void {
  if(this.storageService.getCookie('cookies')){
    this.acceptCookies = true;
  }
}
cookies(state:number) {
  this.acceptCookies = true;
  
  if(state == 0){
    //speichere die Cookie Einstellungen
    this.toggleLearnMore()
    this.storageService.setCookie('cookies', JSON.stringify([this.requiredCookies, this.personalizedCookies, this.marketingCookies]))
  }else{
    this.storageService.setCookie('cookies', JSON.stringify([1,1,1]))
  }
}
toggleLearnMore() {
  this.showLearnMore = !this.showLearnMore;
  this.hideBanner = !this.hideBanner
  console.log(this.showLearnMore);
}

toggleCookie(cookieType: string): void {
  switch (cookieType) {
    case 'requiredCookies':
      // Erforderliche Cookies können nicht deaktiviert werden
      break;
    case 'personalizedCookies':
      this.personalizedCookies = this.personalizedCookies === 0 ? 1 : 0;
      break;
    case 'marketingCookies':
      this.marketingCookies = this.marketingCookies === 0 ? 1 : 0;
      break;
  }
}
}
