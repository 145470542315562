<!-- Footer Section Begin -->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer__about">
                    <div class="footer__logo">
                        <a [routerLink]="['/']"><img class="logo_img" src="../../../assets/img/logo2.png" alt=""></a>
                    </div>
                    <p>2-Euro Gedenkmünzen & numismatische Produkte -  Ihr vertrauensvoller Münzhandel seit zwei Jahrzehnten. </p>
                    <a href="#"><img src="../../../assets/img/payment.png" alt=""></a>
                </div>
            </div>
            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                <div class="footer__widget">
                    <h6>Produkte</h6>
                    <ul>
                        <li><a [routerLink]="['/shop']" [queryParams]= "{cond:'Unc'}">Unzirkuliert</a></li>
                        <li><a [routerLink]="['/shop']" [queryParams]= "{cond:'KMS'}">Sets</a></li>
                        <li><a [routerLink]="['/shop']" [queryParams]= "{cond:'20'}">20€</a></li>
                        <li><a [routerLink]="['/shop']" [queryParams]= "{cond:'Sale'}">Sale</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="footer__widget">
                    <h6>Hilfe</h6>
                    <ul>
                        <li><a [routerLink]="['/contact']">Kontakt</a></li>
                        <li><a [routerLink]="['/policies/legal-notice']">Impressum</a></li>
                        <li><a [routerLink]="['/policies/terms-of-service']">AGBs</a></li>
                        <li><a [routerLink]="['/policies/refund-policy']">Rückgabe & Umtausch</a></li>
                        <li><a [routerLink]="['/policies/privacy-policy']">Datenschutzerklärung</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
                <div class="footer__widget">
                    <h6>NewLetter</h6>
                    <div class="footer__newslatter">
                        <p>Sei der Erste, der von neuen Produkten, Rabatten und exklusiven Angeboten erfährt!</p>
                        <form action="#">
                            <input type="text" placeholder="Deine E-Mail Adresse" name="mail" [(ngModel)]="mail"
                                (keydown.enter)="signUp()">
                            <button type="submit" (click)="signUp()"><span class="icon_mail_alt"></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="footer__copyright__text">
                    <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                    <p>Copyright ©
                        <script>
                            document.write(new Date().getFullYear());
                        </script>2024
                        All rights reserved | This template is made by Colorlib 
                    </p>
                    <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                </div>
            </div>
        </div>
    </div>
    <app-cookies></app-cookies>

</footer>
<!-- Footer Section End -->